<template>
<div>
  <h1>噗噗Timeline</h1>
  <el-input placeholder="搜索" v-model="input" class="input-with-select" @keyup.enter.native="search">
    <el-select v-model="mode" slot="prepend" placeholder="请选择">
      <el-option label="树洞号" value="1"></el-option>
      <el-option label="用户ITSC" value="2"></el-option>
      <el-option label="关键词" value="3"></el-option>
    </el-select>
    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
  </el-input>
  
    <el-table :data="postList" style="width: 100%" v-loading="loading">

    <el-table-column label="编号" width="80">
      <template slot-scope="scope">
        <span @click="nav2Post(scope.row)">{{ scope.row.post_id }}</span>
      </template>
    </el-table-column>

    <el-table-column label="状态" width="60">
      <template slot-scope="scope">
        <el-tag size="medium" type="success" v-if="scope.row.post_public == 1">公开</el-tag>
        <el-tag size="medium" type="warning" v-if="scope.row.post_public == 2">私密</el-tag>
        <el-tag size="medium" type="danger" v-if="scope.row.post_public == 3">隐藏</el-tag>
        <el-tag size="medium" type="info" v-if="scope.row.post_public == 0">删除</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="内容" width="600">
      <template slot-scope="scope">
        <span @click="nav2Post(scope.row)">{{ scope.row.post_msg }}</span>
      </template>
    </el-table-column>

    <el-table-column label="时间" width="100">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span>{{ scope.row.post_date }}</span>
      </template>
    </el-table-column>

    <el-table-column label="用户" width="100">
      <template slot-scope="scope">
        <el-tag size="medium" type="info" v-if="scope.row.post_alias=='null'" @click="nav2User(scope.row)">{{ scope.row.user_itsc }}</el-tag>
        <el-tag size="medium" v-else @click="nav2User(scope.row)">{{ scope.row.user_itsc }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="话题" width="50">
      <template slot-scope="scope">
        <span >{{ scope.row.post_topic }}</span>
      </template>
    </el-table-column>

    <el-table-column label="评论" width="50">
      <template slot-scope="scope">
        <span >{{ scope.row.comment_num }}</span>
      </template>
    </el-table-column>

    <el-table-column label="围观" width="50">
      <template slot-scope="scope">
        <span >{{ scope.row.follower_num }}</span>
      </template>
    </el-table-column>

    <el-table-column label="操作">
      <template slot-scope="scope">
          <el-button
          size="mini" type="success"
          @click="handlePublic(scope.row)" v-if="scope.row.post_public != 1">公开</el-button>
        <el-button
          size="mini" type="warning"
          @click="handlePrivate(scope.row)" v-if="scope.row.post_public == 1">隐藏</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.row)" v-if="scope.row.post_public != 0">删除</el-button>
      </template>
    </el-table-column>

  </el-table>
  <el-pagination background layout="prev, pager, next, jumper" @current-change="handlePageChange"></el-pagination>
  </div>
</template>

<script>
    export default{
        created(){
            this.getPost()
        },
        data(){
        return{
            page:0,
            postList:[],
            loading:false,
            mode:'',
            input:''
        }
    },
        methods:{
            async getPost() {
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                this.loading = true
                const {data:res} = await this.$http.post('post/get.php', "token="+token+"&page="+this.page );
                console.log(res)
                this.loading = false
                if (res.code == 0){
                    this.postList = res.postList
                }
                else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            handlePageChange (page){
                this.page = page - 1
                this.postList = []
                this.getPost()
            },
            async handleDelete (e){
                const post_id = e.post_id
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('post/delete.php', "token="+token+"&post_id="+post_id );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('删除成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async handlePublic (e){
                const post_id = e.post_id
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('post/public.php', "token="+token+"&post_id="+post_id );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('设公开成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async handlePrivate (e){
                const post_id = e.post_id
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('post/private.php', "token="+token+"&post_id="+post_id );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('设私密成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            nav2Post(e){
                const routeUrl = this.$router.resolve('/content?post_id=' + e.post_id)
                window.open(routeUrl .href, '_blank');
            },
            nav2User(e){
                const routeUrl = this.$router.resolve('/user?user_itsc=' + e.user_itsc)
                window.open(routeUrl .href, '_blank');
            },
            search(){
              if(this.mode == 1){
                const e = {"post_id":this.input}
                this.nav2Post(e)
              }else if(this.mode == 2){
                const e = {"user_itsc":this.input}
                this.nav2User(e)
              }
              else if(this.mode == 3){
                const routeUrl = this.$router.resolve('/search?search_text=' + this.input)
                window.open(routeUrl .href, '_blank');
              }
            }
        }
    }
</script>

<style lang="less">
.el-table .cell {
    padding-right: 0;
}
.el-select .el-input {
    width: 130px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>