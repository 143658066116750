import Vue from 'vue'
import { Button, Form, FormItem, Input, Message, Container, Header, Aside, Main, Menu, Submenu, MenuItem, MenuItemGroup, 
    RadioGroup, RadioButton, Table, TableColumn, Tag, Popover, Pagination, Loading, Descriptions, DescriptionsItem, Select, Option, Row,
    Upload } from 'element-ui'
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Popover)
Vue.use(Pagination)
Vue.use(Loading)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Row)
Vue.use(Upload)

Vue.prototype.$message = Message