<template>
    <div>



        <h1>删除/禁言</h1>
        <el-form label-width="80px" :model="adminForm" label-position="left" :rules="adminFormRules"  ref="adminFormRef">
                <el-form-item label="树洞号" prop="post_id">
                    <el-input v-model="adminForm.post_id" placeholder="请输入树洞号"></el-input>
                </el-form-item>
                <el-form-item label="评论楼层" prop="comment_order">
                    <el-input v-model="adminForm.comment_order" placeholder="G层为0，LG1为1，洞主留空"></el-input>
                </el-form-item>
        </el-form>
        <el-row class="btnGroup">
            <el-button type="warning" plain  @click="handleDelete">删除</el-button>
            <el-button type="success" plain  @click="handlePublic">恢复</el-button>
            <el-button type="danger" plain  @click="silent('+ 1 hour')">禁言1小时</el-button>
            <el-button type="danger" plain  @click="silent('+ 1 day')">禁言1天</el-button>
            <el-button type="danger" plain @click="silent('+ 1 week')">禁言1周</el-button>
            <el-button type="success" plain @click="silent('- 1 minute')">解除禁言</el-button>
        </el-row>

        <h1>置顶</h1>
        <el-form label-width="80px" :model="topForm" label-position="left" :rules="topFormRules"  ref="topFormRef">
                <el-form-item label="树洞号" prop="post_id">
                    <el-input v-model="topForm.post_id" placeholder="请输入树洞号,取消置顶为0"></el-input>
                </el-form-item>
                <el-form-item label="标签内容" prop="tag_content">
                    <el-input v-model="topForm.tag_content" placeholder="置顶显示的标签内容"></el-input>
                </el-form-item>
        </el-form>
        <el-row class="btnGroup">
            <el-button type="success"  @click="handleTop">置顶</el-button>
        </el-row>


    </div>
</template>

<script>
export default {
    data(){
        return{
            adminForm:{
                post_id:'',
                comment_order:''
            },
            adminFormRules:{
                post_id:[
                    { required: true, message: '请输入树洞号', trigger: 'blur' }
                ]
            },
            topForm:{
                post_id:'',
                tag_content:''
            },
            topFormRules:{
                post_id:[
                    { required: true, message: '请输入树洞号', trigger: 'blur' }
                ],
                tag_content:[
                    { required: true, message: '请输入标签内容', trigger: 'blur' }
                ],
            },
            userInfo:[],
            mode:'',
            input:'',
        }
    },
    methods:{
        handleDelete(){
            this.$refs.adminFormRef.validate(async (valid)=>{
                if(!valid) return;
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                if(this.adminForm.comment_order == ''){
                    const {data:res} = await this.$http.post('post/delete.php', "token="+token+"&post_id="+this.adminForm.post_id );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('删除成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }else{
                    const {data:res} = await this.$http.post('comment/deleteByPost.php', "token="+token+"&post_id="+this.adminForm.post_id+"&comment_order="+this.adminForm.comment_order );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('删除成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }

            })
        },
        handlePublic(){
            this.$refs.adminFormRef.validate(async (valid)=>{
                if(!valid) return;
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                if(this.adminForm.comment_order == ''){
                    const {data:res} = await this.$http.post('post/public.php', "token="+token+"&post_id="+this.adminForm.post_id );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('恢复成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }else{
                    const {data:res} = await this.$http.post('comment/publicByPost.php', "token="+token+"&post_id="+this.adminForm.post_id+"&comment_order="+this.adminForm.comment_order );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('恢复成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }

            })
        },
        silent(e){
            this.$refs.adminFormRef.validate(async (valid)=>{
                if(!valid) return;
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                if(this.adminForm.comment_order == ''){
                    const {data:res} = await this.$http.post('user/silentByPost.php', "token="+token+"&post_id="+this.adminForm.post_id+"&silent_time="+e  );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('操作成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }else{
                    const {data:res} = await this.$http.post('user/silentByComment.php', "token="+token+"&post_id="+this.adminForm.post_id+"&comment_order="+this.adminForm.comment_order+"&silent_time="+e  );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('操作成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }

            })
        },
        handleTop(){
            this.$refs.topFormRef.validate(async (valid)=>{
                if(!valid) return;
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('post/top.php', "token="+token+"&post_id="+this.topForm.post_id+"&tag_content="+this.topForm.tag_content );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('置顶成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }

            })
        },
        search(){
              if(this.mode == 1){
                this.searchByPootalID(this.input)
              }else if(this.mode == 2){
                this.searchByNameEn(this.input)
              }
        },
        async searchByNameEn(e){
            const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('user/searchByNameEn.php', "token="+token+"&user_name_en="+e );
                    console.log(res)
                    if (res.code == 0){
                        this.userInfo = res.userInfo
                    }else if (res.code == 1){
                        this.$message.error('用户不存在')
                    }else if (res.code == 5){
                        this.$message.error('用户ITSC不得为空')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
        },
        async searchByPootalID(e){
            const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('user/searchByPootalID.php', "token="+token+"&user_pootal_id="+e );
                    console.log(res)
                    if (res.code == 0){
                        this.userInfo = res.userInfo
                    }else if (res.code == 1){
                        this.$message.error('用户不存在')
                    }else if (res.code == 5){
                        this.$message.error('用户ITSC不得为空')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
        },
        nav2User(e){
                const routeUrl = this.$router.resolve('/user?user_itsc=' + e.user_itsc)
                window.open(routeUrl .href, '_blank');
        }
    }
}
</script>

<style lang="less" scoped>
.btn {
    position: relative;
    left: 50%;
    transform: translate(-50%);
}
.btnGroup{
    text-align: center;
    margin-top:15px;
}
.el-table .cell {
    padding-right: 0;
}
</style>