<template>
<div>
    <h1>用户：{{user_itsc}}</h1>

    <el-input placeholder="搜索" v-model="input" class="input-with-select" @keyup.enter.native="search">
    <el-select v-model="mode" slot="prepend" placeholder="请选择">
      <el-option label="树洞号" value="1"></el-option>
      <el-option label="用户ITSC" value="2"></el-option>
      <el-option label="关键词" value="3"></el-option>
    </el-select>
    <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
  </el-input>

    <h4>用户信息</h4>
  <el-descriptions class="margin-top" style="width: 100%;" :column="3" border v-loading="info_loading">
    <el-descriptions-item>
      <template slot="label">
        ITSC
      </template>
      <el-tag size="medium" @click="nav2User(userInfo)">{{userInfo.user_itsc}}</el-tag>
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        Pootal ID
      </template>
      {{userInfo.user_serial}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        禁言状态
      </template>
      {{userInfo.user_silent_until}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">
        操作
      </template>
        <el-tag size="medium" type="danger" style="margin-left:10px" @click="silent('+ 1 hour')">禁言一小时</el-tag>
        <el-tag size="medium" type="danger" style="margin-left:10px" @click="silent('+ 1 day')">禁言一天</el-tag>
        <el-tag size="medium" type="danger" style="margin-left:10px" @click="silent('+ 7 day')">禁言一周</el-tag>
        <el-tag size="medium" type="success" style="margin-left:10px" @click="silent('- 1 minute')">解除禁言</el-tag>
    </el-descriptions-item>
  </el-descriptions>

<h4>发表的树洞</h4>
  <el-table :data="postList" style="width: 100%;margin-top:20px;"  v-loading="post_loading">

    <el-table-column label="编号" width="80">
      <template slot-scope="scope">
        <span @click="nav2Post(scope.row)">{{ scope.row.post_id }}</span>
      </template>
    </el-table-column>

    <el-table-column label="状态" width="60">
      <template slot-scope="scope">
        <el-tag size="medium" type="success" v-if="scope.row.post_public == 1">公开</el-tag>
        <el-tag size="medium" type="warning" v-if="scope.row.post_public == 2">私密</el-tag>
        <el-tag size="medium" type="danger" v-if="scope.row.post_public == 3">隐藏</el-tag>
        <el-tag size="medium" type="info" v-if="scope.row.post_public == 0">删除</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="内容" width="600">
      <template slot-scope="scope">
        <span @click="nav2Post(scope.row)">{{ scope.row.post_msg }}</span>
      </template>
    </el-table-column>

    <el-table-column label="时间" width="100">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span>{{ scope.row.post_date }}</span>
      </template>
    </el-table-column>

    <el-table-column label="用户" width="100">
      <template slot-scope="scope">
        <el-tag size="medium" type="info" v-if="scope.row.post_alias=='null'" @click="nav2User(scope.row)">{{ scope.row.user_itsc }}</el-tag>
        <el-tag size="medium" v-else @click="nav2User(scope.row)">{{ scope.row.user_itsc }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="话题" width="50">
      <template slot-scope="scope">
        <span >{{ scope.row.post_topic }}</span>
      </template>
    </el-table-column>

    <el-table-column label="评论" width="50">
      <template slot-scope="scope">
        <span >{{ scope.row.comment_num }}</span>
      </template>
    </el-table-column>

    <el-table-column label="围观" width="50">
      <template slot-scope="scope">
        <span >{{ scope.row.follower_num }}</span>
      </template>
    </el-table-column>

    <el-table-column label="操作" width="200">
      <template slot-scope="scope">
          <el-button
          size="mini" type="success"
          @click="handlePublic(scope.row)" v-if="scope.row.post_public != 1">公开</el-button>
        <el-button
          size="mini" type="warning"
          @click="handlePrivate(scope.row)" v-if="scope.row.post_public == 1">隐藏</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.row)" v-if="scope.row.post_public != 0">删除</el-button>
      </template>
    </el-table-column>

  </el-table>
  <el-pagination background layout="prev, pager, next, jumper" @current-change="handlePostPageChange"></el-pagination>

<h4>发表的评论</h4>
    <el-table :data="commentList" style="width: 100%;margin-top:20px;" v-loading="comment_loading">

    <el-table-column label="树洞号" width="80">
      <template slot-scope="scope">
        <span @click="nav2Post(scope.row)">{{ scope.row.post_id }}</span>
      </template>
    </el-table-column>

    <el-table-column label="状态" width="60">
      <template slot-scope="scope">
        <el-tag size="medium" type="success" v-if="scope.row.comment_public == 1">公开</el-tag>
        <el-tag size="medium" type="info" v-if="scope.row.comment_public == 0">删除</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="评论" width="600">
      <template slot-scope="scope">
        <span @click="nav2Post(scope.row)">{{ scope.row.comment_msg }}</span>
      </template>
    </el-table-column>

    <el-table-column label="时间" width="100">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span>{{ scope.row.comment_date }}</span>
      </template>
    </el-table-column>

    <el-table-column label="用户" width="100">
      <template slot-scope="scope">
        <el-tag size="medium" type="info" v-if="scope.row.comment_alias=='null'" @click="nav2User(scope.row)">{{ scope.row.user_itsc }}</el-tag>
        <el-tag size="medium" v-else @click="nav2User(scope.row)">{{ scope.row.user_itsc }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column label="编号" width="70">
      <template slot-scope="scope">
        <span >{{ scope.row.comment_id }}</span>
      </template>
    </el-table-column>

    <el-table-column label="操作">
      <template slot-scope="scope">
          <el-button
          size="mini" type="success"
          @click="handleCommentPublic(scope.row)" v-if="scope.row.comment_public == 0">恢复</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="handleCommentDelete(scope.row)" v-if="scope.row.comment_public == 1">删除</el-button>
      </template>
    </el-table-column>

  </el-table>
  <el-pagination background layout="prev, pager, next, jumper" @current-change="handleCommentPageChange"></el-pagination>

  </div>
</template>

<script>
    export default{
        created(){
            this.user_itsc = this.$route.query.user_itsc
            this.getInfo()
            this.getPost()
            this.getComment()
        },
        data(){
        return{
            user_itsc:'',
            postList:[],
            post_page:0,
            post_loading:false,

            commentList:[],
            comment_page:0,
            comment_loading:false,

            userInfo:[],
            info_loading:false,
            mode:'',
            input:''
        }
    },
        methods:{
            async getInfo() {
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                this.info_loading = true
                const {data:res} = await this.$http.post('user/info.php', "token="+token+"&user_itsc="+this.user_itsc );
                console.log(res)
                this.info_loading = false
                if (res.code == 0){
                    this.userInfo = res.userInfo
                }else if (res.code == 1){
                    this.$message.error('用户不存在')
                }else if (res.code == 5){
                    this.$message.error('用户ITSC不得为空')
                }else if (res.code == 303){
                    this.$message.error('受保护的用户')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async getPost() {
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                this.post_loading = true
                const {data:res} = await this.$http.post('user/post.php', "token="+token+"&user_itsc="+this.user_itsc+"&page="+this.post_page );
                console.log(res)
                this.post_loading = false
                if (res.code == 0){
                    this.postList = res.postList
                }else if (res.code == 5){

                }else if (res.code == 303){
                    this.$message.error('受保护的用户')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async getComment() {
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                this.comment_loading = true
                const {data:res} = await this.$http.post('user/comment.php', "token="+token+"&user_itsc="+this.user_itsc+"&page="+this.comment_page );
                console.log(res)
                this.comment_loading = false
                if (res.code == 0){
                    this.commentList = res.commentList
                }else if (res.code == 5){

                }else if (res.code == 303){
                    this.$message.error('受保护的用户')
                }
                else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            handlePostPageChange (page){
                this.post_page = page - 1
                this.postList = []
                this.getPost()
            },
            handleUniPostPageChange (page){
                this.uni_post_page = page - 1
                this.uniPostList = []
                this.getUniPost()
            },
            handleCommentPageChange (page){
                this.comment_page = page - 1
                this.commentList = []
                this.getComment()
            },
            handleUniCommentPageChange (page){
                this.uni_comment_page = page - 1
                this.uniCommentList = []
                this.getUniComment()
            },
            async handleDelete (e){
                const post_id = e.post_id
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('post/delete.php', "token="+token+"&post_id="+post_id );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('删除成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async handlePublic (e){
                const post_id = e.post_id
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('post/public.php', "token="+token+"&post_id="+post_id );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('设公开成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async handlePrivate (e){
                const post_id = e.post_id
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('post/private.php', "token="+token+"&post_id="+post_id );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('设私密成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async handleCommentDelete (e){
                const comment_id = e.comment_id
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('comment/delete.php', "token="+token+"&comment_id="+comment_id );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('删除成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async handleCommentPublic (e){
                const comment_id = e.comment_id
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('comment/public.php', "token="+token+"&comment_id="+comment_id );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('恢复成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            async silent (e){
                const user_itsc = this.userInfo.user_itsc
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                const {data:res} = await this.$http.post('user/silent.php', "token="+token+"&user_itsc="+user_itsc+"&silent_time="+e );
                console.log(res)
                if (res.code == 0){
                    this.$message.success('操作成功')
                }else{
                    window.sessionStorage.removeItem('token')
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                }
            },
            nav2Post(e){
                const routeUrl = this.$router.resolve('/content?post_id=' + e.post_id)
                window.open(routeUrl .href, '_blank');
            },
            nav2User(e){
                const routeUrl = this.$router.resolve('/user?user_itsc=' + e.user_itsc)
                window.open(routeUrl .href, '_blank');
            },
            search(){
              if(this.mode == 1){
                const e = {"post_id":this.input}
                this.nav2Post(e)
              }else if(this.mode == 2){
                const e = {"user_itsc":this.input}
                this.nav2User(e)
              }
              else if(this.mode == 3){
                const routeUrl = this.$router.resolve('/search?search_text=' + this.input)
                window.open(routeUrl .href, '_blank');
              }
            }
        }
    }
</script>

<style lang="less" scoped>
.el-table .cell {
    padding-right: 0;
}
</style>