<template>
    <div style="margin:30px">
        <h4>噗噗后台</h4>
        <el-form label-width="80px" :model="adminForm" label-position="left" :rules="adminFormRules"  ref="adminFormRef">
                <el-form-item label="树洞号" prop="post_id">
                    <el-input v-model="adminForm.post_id" placeholder="请输入树洞号"></el-input>
                </el-form-item>
                <el-form-item label="评论楼层" prop="comment_order">
                    <el-input v-model="adminForm.comment_order" placeholder="G层为0，LG1为1，洞主留空"></el-input>
                </el-form-item>
        </el-form>
        <el-row class="btnGroup" style="margin-top:30px;">
            <el-button type="warning" plain style="width:100px;margin-right: 20px" @click="handleDelete">删除</el-button>
            <el-button type="success" plain style="width:100px;margin-left: 20px" @click="handlePublic">恢复</el-button>
        </el-row>
        <el-row class="btnGroup">
            <el-button type="danger" plain style="width:100px;margin-right: 20px" @click="silent('+ 1 hour')">禁言1小时</el-button>
            <el-button type="danger" plain style="width:100px;margin-left: 20px" @click="silent('+ 1 day')">禁言1天</el-button>
        </el-row>
        <el-row class="btnGroup">
            <el-button type="danger" plain style="width:100px;margin-right: 20px" @click="silent('+ 1 week')">禁言1周</el-button>
            <el-button type="success" plain style="width:100px;margin-left: 20px" @click="silent('- 1 minute')">解除禁言</el-button>
        </el-row>


    </div>
    
</template>

<script>
export default {
    data(){
        return{
            adminForm:{
                post_id:'',
                comment_order:''
            },
            adminFormRules:{
                post_id:[
                    { required: true, message: '请输入树洞号', trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        handleDelete(){
            this.$refs.adminFormRef.validate(async (valid)=>{
                if(!valid) return;
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                if(this.adminForm.comment_order == ''){
                    const {data:res} = await this.$http.post('post/delete.php', "token="+token+"&post_id="+this.adminForm.post_id );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('删除成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }else{
                    const {data:res} = await this.$http.post('comment/deleteByPost.php', "token="+token+"&post_id="+this.adminForm.post_id+"&comment_order="+this.adminForm.comment_order );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('删除成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }

            })
        },
        handlePublic(){
            this.$refs.adminFormRef.validate(async (valid)=>{
                if(!valid) return;
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                if(this.adminForm.comment_order == ''){
                    const {data:res} = await this.$http.post('post/public.php', "token="+token+"&post_id="+this.adminForm.post_id );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('恢复成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }else{
                    const {data:res} = await this.$http.post('comment/publicByPost.php', "token="+token+"&post_id="+this.adminForm.post_id+"&comment_order="+this.adminForm.comment_order );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('删除成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }

            })
        },
        silent(e){
            this.$refs.adminFormRef.validate(async (valid)=>{
                if(!valid) return;
                const token = window.sessionStorage.getItem('token')
                if(!token){
                    this.$message.error('请先登录')
                    this.$router.push('/login')
                    return
                }
                if(this.adminForm.comment_order == ''){
                    const {data:res} = await this.$http.post('user/silentByPost.php', "token="+token+"&post_id="+this.adminForm.post_id+"&silent_time="+e  );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('操作成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }else{
                    const {data:res} = await this.$http.post('user/silentByComment.php', "token="+token+"&post_id="+this.adminForm.post_id+"&comment_order="+this.adminForm.comment_order+"&silent_time="+e  );
                    console.log(res)
                    if (res.code == 0){
                        this.$message.success('操作成功')
                    }else{
                        window.sessionStorage.removeItem('token')
                        this.$message.error('请先登录')
                        this.$router.push('/login')
                    }
                }

            })
        }
    }
}
</script>

<style lang="less" scoped>
.btn {
    position: relative;
    left: 50%;
    transform: translate(-50%);
}
.btnGroup{
    text-align: center;
    margin-top:15px;
}

</style>